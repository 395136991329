function Footer(props) {
  return (
    <>
      <div className="footer">
        <div className="ft-top">
          18 Great Guildford St, London SE1 0FD, United Kingdom| Phone:
          +44 7507 301593| Email: support@peaktrades.com
        </div>
        <br />
        <div className="ft-btm">Copyright © 2021</div>
      </div>
    </>
  );
}

export default Footer;
