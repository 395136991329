import Navbartoo from "../Components/Navbartoo";
import Navbar from "../Components/Navbar/Navbar";
import Reasons from "../Components/Reasons";
import React, { useEffect, useState } from "react";
import P5 from "../Images/ip1.jpg";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function InvestmentProducts(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Investment Products</h1>
      </div>
      <div className="ip">
        <div className="ipimg">
          <img src={P5}></img>
        </div>
        <div className="ipcont">
          <p className="iphead">
            All you need to build and manage your portfolio—in one place
          </p>
          <br />
          <p>
            Having access to a wide variety of online trading and investment
            choices, powerful trading platforms, and research and education can
            help you build a portfolio suited to your needs.
          </p>
          <br />
          <p>
            At peaktrades, we don’t charge platform or data fees. Accessing a
            trading specialist is always free and our low $0.50 flat price per
            online equity trade applies no matter your account balance or
            trading frequency.
          </p>
          <button type="submit" className="myButton">
            <Link to="/signup">Open account</Link>
          </button>
        </div>
      </div>
      <div className="ipcontact">
        <div className="ipcontacttext">
          <p>DO YOU HAVE ANY QUESTION OR WANT TO KNOW MORE ABOUT US…</p>
          {/* <h1>CONTACT US NOW</h1> */}
        </div>
        <div className="butt">
          <button type="submit" className="myButton">
            Contact Us
          </button>
        </div>
      </div>
      <div className="reas">
        <Reasons />
      </div>
      <Footer />
    </>
  );
}

export default InvestmentProducts;
