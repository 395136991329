import React, { useEffect, useState } from "react";
import Card1 from "../Images/five.jpg";
import Card2 from "../Images/one.jpg";
import Card3 from "../Images/real.jpeg";
import P1 from "../Images/profile.jpeg";
import P2 from "../Images/profile6.jpg";
import P3 from "../Images/profile1.jpeg";
import P4 from "../Images/profile5.jpg";
import A1 from "../Images/avi1.png";
import A2 from "../Images/avi9.png";
import A3 from "../Images/avi3.png";
import A4 from "../Images/avi4.png";
import A5 from "../Images/avi5.png";
import A6 from "../Images/avi6.png";
import Sm from "../Images/sm.png";
import Sm1 from "../Images/sm1.jpg";

import Reasons from "../Components/Reasons";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import { FastCounter } from "react-smooth-counter";
import Slider from "infinite-react-carousel";
import i18n from "i18next";
import "../Translations/i18nextConf";

import ".././Styles.css";
import Navbar from "../Components/Navbar/Navbar";
import Navbartoo from "../Components/Navbartoo";
import AnimatedNumber from "react-animated-number";

import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";

import { useTranslation } from "react-i18next";
import BiChart from "../Components/Charts/BiChart";
import Counter from "../Components/Counter";
export default function Home() {
  // useSelector((state) => console.log(state.Ticker, "selector home"));
  // const googleTranslate = () => {
  //   new google();
  // };
  const { t } = useTranslation();
  const lngs = [
    {
      lng: {
        ln: "en",
        name: "English",
      },
    },
    {
      lng: {
        ln: "zh",
        name: "Chinese",
      },
    },
    {
      lng: {
        ln: "ru",
        name: "Russian",
      },
    },
  ];

  console.log(lngs.length);
  return (
    <>
      {/* <Ticker /> */}
      <div className="divcont">
        <div className="div1">
          <Navbar />
          <Navbartoo />
          <div className="intro" data-aos="fade-up">
            <h1>
              We can help you set your
              <br /> financial goal
            </h1>
            <p>
              You cannot discover new oceans unless you have the courage <br />{" "}
              to lose sight of the shore… and move on, Create Account and earn
              $10 Bonus
            </p>{" "}
            <br />
            <div id="google_translate_element"></div>
            <button className="myButton">
              <Link to="/signup">{t("welcome")}</Link>
            </button>
          </div>
        </div>
        <div className="div2">
          <Slider
            dots={false}
            swipe={false}
            autoplay={true}
            arrows={false}
            pauseOnHover={false}
            className="bgcarousel"
          >
            <div className="homebg"></div>
            <div className="homebg1"></div>
            <div className="homebg2"></div>
          </Slider>
        </div>
      </div>
      {/* <button>heyy buttonn</button> */}
      <div id="google_translate_element"></div>
      <div className="main-container">
        <div className="card-container">
          <div className="card" data-aos="fade-zoom-in">
            <div className="card-a">
              <img src={Card1} alt="" />
            </div>
            <div className="card-b">
              {/* <h1>{t("this_is_an_example")}</h1> */}
              <h4>Investment Products with 10-50% Target Yield</h4>
              <p>
                Put your money to work in stocks, bonds, ETFs, and mutual funds.
                Even asset based alternative investments and cryptocurrencies…s
              </p>{" "}
              <br />
              <Link to="/investment-products">
                <button className="myButton">Learn More</button>
              </Link>
            </div>
          </div>
          <div className="card">
            <div className="card-a">
              <img src={Card2} alt="" />
            </div>
            <div className="card-b">
              <h4>Investment guidance to align with your goals</h4>
              <p>
                Ready to do more with your money? It’s easy with peaktrades. We
                help you make informed decisions, get invested, and know exactly
                where you stand.
              </p>
              <br />
              <Link to="/investment-guidance">
                <button className="myButton">Learn More</button>
              </Link>
            </div>
          </div>

          <div className="card">
            <div className="card-a">
              <img src={Card3} alt="" />
            </div>
            <div className="card-b">
              <h4>Take on the market with our powerful platforms</h4>
              <p>
                Trade without trade-offs. Our desktop, web, and mobile platforms
                are designed for performance and built for all levels of
                investors.
              </p>{" "}
              <br />
              <Link to="/about">
                <button className="myButton">Learn More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="accountall">
        <div id="google_translate_element"></div>

        <div>
          <h1>Choose the Account Type that Suits You</h1>
        </div>
        <div className="accountbod">
          <Link to="individual">
            <div>
              <PersonOutlineIcon style={{ color: "ab8353", fontSize: 60 }} />
              <p>Individual Accounts</p>
            </div>
          </Link>
          <Link to="jointaccount">
            <div>
              <PeopleOutlineIcon style={{ color: "#132743", fontSize: 60 }} />
              <p>Joint Accounts</p>
            </div>
          </Link>
          <Link to="retirement">
            <div>
              <GroupsTwoToneIcon style={{ fontSize: 60, color: "gray" }} />
              <p>Retirement Accounts</p>
            </div>
          </Link>
          <Link to="trust">
            <div>
              <EmojiPeopleIcon style={{ color: "#132743", fontSize: 60 }} />
              <p>peak Plans</p>
            </div>
          </Link>
          <Link to="family">
            <div>
              <GroupsIcon style={{ color: "ab8353", fontSize: 60 }} />
              <p>Family Accounts</p>
            </div>
          </Link>
          <Link to="institutional">
            <div>
              <EmojiTransportationIcon
                style={{ color: "#132743", fontSize: 60 }}
              />
              <p>Institutional Accounts</p>
            </div>
          </Link>
        </div>
      </div>
      <div>
        <BiChart />
      </div>
      <div className="counter">
        <div className="counter-txt">
          <div>24Hrs Record</div>
          <div className="num">
            <Counter end={50} suffix="B+" />
          </div>
        </div>
        <div className="counter-txt">
          <div>Current Exchange</div>
          <div className="num">
            <Counter end={12} suffix="B+" />
          </div>
        </div>

        <div className="counter-txt">
          <div>Customers Served</div>
          <div className="num">
            <Counter end={10} suffix="M+" />
          </div>
        </div>

        <div className="counter-txt">
          <div>Countries Supported</div>
          <div>
            <Counter end={34} />
          </div>
        </div>
      </div>
      <div className="profile-container">
        <h4> THE EXPERT TEAM</h4>
        <div className="profile">
          <div className="profile-card">
            <div className="imdiv">
              <img src={P2} alt="" />
            </div>
            <div>
              <h4>Laura Peters</h4>
              <p>Strategist in Stock, Binary Options and Forex trading</p>
            </div>
            <br />
            <br />
          </div>
          <div className="profile-card">
            <div className="imdiv">
              <img src={P1} alt="" />
            </div>
            <div>
              <h4>Annet Silva</h4>
              <p>
                SEO expert and top broker in Stock, Binary and Forex Trading
              </p>
            </div>
            <br />
            <br />
          </div>
          <div className="profile-card">
            <div className="imdiv">
              <img src={P3} alt="" />
            </div>
            <div>
              <h4>Perry Charles</h4>
              <p>
                Professional trader in Stock,Binary options, Cryptocurrency
                Trading and Forex trading
              </p>
            </div>
            <br />
            <br />
          </div>
          <div className="profile-card">
            <div className="imdiv">
              <img src={P4} alt="" />
            </div>
            <div>
              <h4>Vivian Daniels</h4>
              <p>Signal Analyst in Stock, Binary Option and Forex Trading</p>
            </div>
            <br />
            <br />
          </div>
        </div>
        <div className="btm">
          <h1>Explore How we can help you</h1>
          <p>
            The ultimate trading solution <br /> for busy personales who want to
            reach their trading goals
          </p>
        </div>
      </div>
      <div className="xx">
        <div className="xtext">
          <h3>Simple and Easy Way to Trade</h3>
          <p>
            Have time to engage in other activities while Trading with us such
            as your hobby, other businesses and much more…
          </p>
        </div>
      </div>
      <Reasons />
      <div className="review">
        <h3>Traders Review</h3>

        <div>
          <Slider
            dots={false}
            swipe={false}
            autoplay={true}
            arrows={false}
            pauseOnHover={false}
            className="bgcarousel"
          >
            <div>
              <div className="review-a">
                <i>
                  If You are looking for some awesome knowlegeable people in
                  trading, these are the guys I highly recommend. Their
                  friendliness and result driven approach are what I love about
                  them
                </i>
              </div>
              <div className="review-p">
                <img src={Sm} alt="" />

                <p>Stella Stone</p>
              </div>
            </div>
            <div>
              <div className="review-a">
                <i>
                  For years I was trying out all the different ways to make
                  extra money, but most of them were low profit oriented. I am
                  happy I have found a platform I can rely on, with great profit
                  margin
                </i>
              </div>
              <div className="review-p">
                <img src={Sm1} alt="" />

                <p>Rosemary Paul</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      {/* <div className="plans">
  <h3>Choose The Best Account Type for You</h3>
  <div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>

  </div>
</div> */}

      <div className="comment-container">
        <h3>Commentaries</h3>
        <div className="comment">
          <div className="c-card">
            <div>
              <img src={A1} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>John Davis</i>
              </h4>
              <p>
                They’re simply the best trading platform out there. Easy to use
                and beginner friendly interface are one of their best qualities.
              </p>
            </div>
          </div>
          <div className="c-card">
            <div>
              <img src={A2} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>Katherine Dwayne</i>
              </h4>

              <p>
                I must confess that I never really believed in this, not until I
                received about $20,000 direct to my account.
              </p>
            </div>
          </div>

          <div className="c-card">
            <div>
              <img src={A3} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i> Annet Silva</i>
              </h4>

              <p>
                Sir, I was freaking out when I went to pick the $6,000 lol. The
                lady looked at me because I have never picked such a huge sum
                from her store before. I have the $6,000 now sir! Thanks a lot.
              </p>
            </div>
          </div>

          <div className="c-card">
            <div>
              <img src={A4} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>Marybeth Jones</i>
              </h4>

              <p>
                peaktrades stands out as a trusted exchange and their services
                are top notch.
              </p>
            </div>
          </div>

          <div className="c-card">
            <div>
              <img src={A5} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>Stanley Joe</i>
              </h4>

              <p>
                With a team of seasoned trading experts armed with the best
                market trading strategies, they offer extensive trading
                assistance, grow digital assets and help to build your
                portfolio.
              </p>
            </div>
          </div>

          <div className="c-card">
            <div>
              <img src={A6} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>Grace David</i>
              </h4>

              <p>
                Mein PayPal-Konto wurde erst mit 8.967,25 Euro gutgeschrieben,
                nachdem ich 895 Euro bezahlt hatte. Vielen Dank, Expedite
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
}
