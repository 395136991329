import { useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router";

export const ProtectedRoutes = (props) => {
  const { data } = useSelector(({ Auth }) => Auth);
  // console.log(data);
  const history = useHistory;

  return data ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/signin",
        state: { from: props.location },
      }}
    />
  );
};
