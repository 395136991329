import React, { useState } from "react";
import * as Action from "../Redux/Action/Auth";
import { Redirect, Route } from "react-router";
import Logo from "../Images/fidelogo.png";

import { useHistory, Link } from "react-router-dom";
import Fetch from "../Utilities/Fetch";
import { Checkbox } from "@mui/material";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Spinner from "../Utilities/Spinner";
import { useDispatch } from "react-redux";

function SignIn(props) {
  const [spinning, setSpinning] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const history = useHistory();
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });

  const Login = async (e) => {
    e.preventDefault();
    setSpinning(true);

    try {
      const response = await Fetch("auth/login", "post", loginDetails);
      // console.log(response);
      const data = await response.json();
      // console.log(data, data);

      setSpinning(false);
      // console.log(response.ok, "true?");

      if (response.ok) {
        localStorage.setItem(
          "user",
          JSON.stringify(data?.data?.tokens?.refresh?.token)
        );
        localStorage.setItem("userDetails", JSON.stringify(data?.data?.user)); //sets token and user details to localstorage

        dispatch(Action.Auth());
        history.push("/dashboard/overview");
      } else {
        setOpen(true);
        setMessage(data?.errors[0]?.msg);
        console.log(data?.errors[0]?.value, "message");
      }
    } catch (error) {
      // console.log(error);
      setSpinning(false);
      setOpen(true);
      setMessage("Something went wrong. Try again");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
    // console.log(name, value);
  };
  return (
    <>
      <div className="signin">
        <div id="active">
          {" "}
          <Link to="/">
            {" "}
            <img src={Logo} />
          </Link>
        </div>
        {/* <Navbar />
        <Navbartoo /> */}
        <div className="push1"></div>
        <div className="auth-container">
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {message}
              </Alert>
            </Collapse>
          </Box>
          <h1>Sign in</h1>
          <p>Welcome back, login to manage account</p>
          <div className="c-form">
            <form onSubmit={Login}>
              <div className="f-input">
                <div id="btm" className="object-cont">
                  <div className="left-icon">
                    <MailOutlineIcon className="muicon" />
                  </div>
                  <div className="right-object">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div id="btm" className="object-cont">
                  <div className="left-icon">
                    <LockOpenIcon className="muicon" />
                  </div>
                  <div className="right-object">
                    <input
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="xtra">
                <div>
                  {" "}
                  <Checkbox />
                  Remember me
                </div>
                <div className="option-i">
                  <i>
                    <Link to="/forgotpassword">Forgot password?</Link>
                  </i>{" "}
                </div>
              </div>
              <button type="submit" className="form-btn">
                {spinning ? <Spinner /> : "Login"}
              </button>
              <p>Or</p>
              <button className="form-btn2">
                <Link to="/signup"> Create An Account</Link>{" "}
              </button>
            </form>
          </div>
        </div>
        <div className="push"></div>
        <div className="copyrite">
          <Link to="/">peakTrade Inc. </Link>
          <span>© 2021. All Rights Reserved.</span>
        </div>
      </div>
    </>
  );
}

export default SignIn;
