import React, { useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory, Link } from "react-router-dom";
import Fetch from "../Utilities/Fetch";
import Spinner from "../Utilities/Spinner";
import Logo from "../Images/fidelogo.png";

function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [severity, setSeverity] = useState(true);

  const [spinning, setSpinning] = useState(false);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const forgotPassword = async (e) => {
    e.preventDefault();
    setSpinning(true);

    try {
      const response = await Fetch("auth/forgot-password", "post", {
        email: email,
      });
      // console.log(response);
      const data = await response.json();
      setOpen(true);
      // console.log(data);
      setSpinning(false);
      if (response.ok) {
        setSeverity(false);
        setMessage(data.messaag);
      }
    } catch (error) {
      // console.log("errorrrrrrrrr");
      setOpen(true);
      setSpinning(false);
    }
  };
  return (
    <>
      <div className="signin">
        <div>
          {" "}
          <div id="active">
            {" "}
            <Link to="/">
              {" "}
              <img src={Logo} />
            </Link>
          </div>
        </div>
        <div className="push1"></div>
        <div className="auth-container pushforgot">
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
                severity={severity ? "error" : "success"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {message}
              </Alert>
            </Collapse>
          </Box>
          <h1>Forgot Password</h1>
          <p>Enter your email to reset your password</p>
          <div className="c-form">
            <form onSubmit={forgotPassword}>
              <div className="f-input">
                <div id="btm">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                      console.log(e.target.name, e.target.value);
                    }}
                  />
                </div>
              </div>
              <button type="submit" className="form-btn">
                {spinning ? <Spinner /> : "Submit"}{" "}
              </button>
            </form>
          </div>
        </div>
        <div className="push push2"></div>
        <div className="push3"></div>
        <div className="push3"></div>
        <div className="push3"></div>
        <div className="copyrite">
          <Link to="/">peakTrade Inc. </Link>
          <span>© 2021. All Rights Reserved.</span>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
