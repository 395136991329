import { IS_AUTHENTICATED } from "../Constants";

export const Auth = (data) => {
  return (dispatch) => {
    dispatch({
      payload: data,
      type: IS_AUTHENTICATED,
    });
  };
};
