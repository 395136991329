import React from "react";
import TradeViewChart from "react-crypto-chart";
export default function BiChart() {
  const chartLayout = {
    layout: {
      backgroundColor: "black",
      textColor: "grey",
    },
    timeScale: {
      borderColor: "#485c7b",
      timeVisible: true,
      secondsVisible: false,
    },
  };
  return (
    <>
      <TradeViewChart pair="BTCBUSD" chartLayout={chartLayout} />
    </>
  );
}
