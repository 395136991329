import { IS_AUTHENTICATED } from "../Constants";

const token = JSON.parse(localStorage.getItem("user"));

const initialState = {
  data: token,
  // token:token
};

export default (state = initialState, action) => {
  switch (action?.type) {
    case IS_AUTHENTICATED:
      return {
        ...state,
        data: true,
      };
    default:
      return state;
  }
};
