import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/fidelogo.png";
import Fetch from "../Utilities/Fetch";

export default function ErrorScreen() {
  // const resendConfirmEmail = async () => {
  //   try {
  //     const response = await Fetch("auth/send-verification-email", "post", {
  //       email: email,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      <div className="error-cont">
        <div>
          <Link to="/">
            {" "}
            <img src={Logo} />
          </Link>
          <h3>Oops! Email is not recognized</h3>
          <p>Click the button below to resend confirmation link </p>
        </div>
        <div>
          <button>Resend </button>
        </div>
      </div>
    </>
  );
}
