import Navbartoo from "../Components/Navbartoo";
import Navbar from "../Components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import Card2 from "../Images/one.jpg";
import Card3 from "../Images/four.jpg";
import A4 from "../Images/avi1.png";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function StockTrading(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Stocks</h1>
      </div>
      <div>
        <div className="igbod stockbod">
          <h1>Stock Market Trading Made Easy</h1>
          <div className="igcont">
            <div className="igtext">
              Long gone are the days when stock trading was reserved for the
              billionaires. Thanks to online stock market trading platforms, it
              is now accessible to anyone. You no longer require the help of a
              Wall Street broker to buy and sell shares on your behalf. All you
              need is a computer, Internet connection, a small fortune, and
              strong research skills. Using online brokerages cuts out the
              middleman, thus, eliminating the necessity to pay the broker’s
              commission. However, the risks stay the same. No matter the amount
              of money you decide to invest in the market, there is always a
              possibility to lose it. But don’t be worry! Our powerful online
              trading tools and advice from our top specialists will help you
              invest your money wisely and reach your financial goals in the
              shortest time.
              <p>
                <button type="submit" className="myButton">
                  <Link to="/signup">Register with Us</Link>
                </button>
              </p>
            </div>
            <div className="igimg">
              <img src={Card2}></img>
            </div>
          </div>
        </div>
        <div className="igbod stockbod">
          <h1>How Do Online Stock Market Platforms Work?</h1>
          <div className="igcont">
            <div className="igimg">
              <img src={Card3}></img>
            </div>
            <div className="igtext">
              <li>
                Stock trading is a process of buying and selling shares – which
                are tiny fractions of a company – to receive financial gains.
                When you acquire shares, you become a shareholder of a company
                investing your money into its future. The share prices depend on
                multiple factors such as the state of economy and market,
                company performance or other investors’ attitude. There are two
                types of stocks:
              </li>
              <p>
                <li>
                  <span>
                    Income stocks (when a company makes profit and issues
                    dividends to shareholders)
                  </span>
                </li>
                <li>
                  <span>
                    Growth stocks (when a company makes profit and re-invests it
                    to develop the business)
                  </span>
                </li>
              </p>
              <p>
                Simply investing in stocks is slightly different from stock
                trading. Even though they both mean acquiring shares, investing
                is more of a long-term commitment, whereas, trading implies
                short-time ownership. If you are looking to be a shareholder and
                receive passive income, investment is the way to go. But if you
                are interested in making money here and now, try stock trading!
              </p>
              <p>
                peaktrades is one of the best online stock investing platforms
                on the Internet. How are we different from Wall Street? Most
                human brokers work for the companies. They don’t care about your
                well-being. Their job is to talk you into buying shares at the
                highest price even if they are not profitable. Online stock
                trading gives you an opportunity to decide for yourself which
                shares you want to buy or sell. There is no one talking in your
                ear trying to cloud your judgment.
              </p>
              <p>
                Moreover, peaktrades offers reliable online stock trading
                software to make the whole process even easier for you! At our
                website, you can always find fresh stock quotes as well as
                market news and prognosis. We also offer a free first
                consultation prior to opening an account. A stock market expert
                will help you set clear financial goals and make a plan on how
                to reach them. Call us at +1 662-586-1740 to set up an
                appointment!
              </p>
            </div>
          </div>
        </div>
        <div className="igbod stockbod">
          <h1>Minimize the Risk</h1>
          <div>
            <p>
              Stock market trading is a risky business. No matter how much
              fundamental or technical analysis you have done, the market is
              still largely unpredictable. There is always a possibility that
              stocks will suddenly drop and eat up your money.
            </p>
            <p>
              Nonetheless, there is a way to minimize the loss. At peaktrades,
              you can insure a certain percentage of your capital to provide a
              safety net for your risky endeavors. Do you have more questions
              about our services? Don’t hesitate to contact us at any time!
            </p>
            <p>
              <button type="submit" className="myButton">
                <Link to="/signup">Open account</Link>
              </button>
            </p>
          </div>
        </div>
        <div className="igbod stockbod laststock">
          <h1>Why choose peaktrades for stock trading?</h1>
          <p>
            <li>
              <span>No subscription or platform fees</span>– Get access to any
              of our trading platforms, streaming news, and expert research
              without an additional fee.
            </li>
            <li>
              <span>Intelligent order routing</span> – Enter stock orders
              knowing that we’re committed to route your order quickly to pursue
              execution at the best available price.
            </li>
            <li>
              <span>Powerful stock trading platforms </span> – Use our Web and
              Mobile Platforms to help you reach your full trading potentials…
            </li>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default StockTrading;
