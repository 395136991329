import React, { useState } from "react";

import { Checkbox } from "@mui/material";
import { useParams } from "react-router";

import { useHistory, Link } from "react-router-dom";
import Fetch from "../Utilities/Fetch";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Spinner from "../Utilities/Spinner";

function SignUp() {
  const [spinning, setSpinning] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState(true);
  const { uin } = useParams();
  // console.log(uin, "uin");
  const history = useHistory();
  // registration
  const [registerDetails, setRegisterDetails] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    referralCode: uin,
  });

  const Register = async (e) => {
    e.preventDefault();
    setSpinning(true);
    try {
      const response = await Fetch("auth/register", "post", registerDetails);
      // console.log(response, "response");
      const data = await response.json();
      // console.log(data, "data")
      setSpinning(false);
      localStorage.setItem("uin", JSON.stringify(data?.data?.user?.uin));
      if (response.ok) {
        setOpen(true);
        setSeverity(false);
        setMessage(
          "Registered Successfully! Please Check your mail to verify your email"
        );
      } else {
        setOpen(true);
        setSeverity(true);
        setMessage(data?.errors[0]?.msg);
      }
      // history.push("/home");
    } catch (error) {
      setSpinning(false);
      console.log("error");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterDetails({
      ...registerDetails,
      [name]: value,
    });
    console.log(name, value);
  };
  return (
    <>
      <div className="signin">
        <div className="ack"> </div>
        <div className="push1"></div>
        <div className="auth-container">
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
                severity={severity ? "error" : "success"}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {message}
              </Alert>
            </Collapse>
          </Box>
          <h1>Sign up</h1>
          <p>The best place to invest</p>
          <div className="c-form">
            <form onSubmit={Register}>
              <div className="f-input">
                <div id="btm" className="object-cont">
                  <div className="left-icon">
                    <MailOutlineIcon className="muicon" />
                  </div>
                  <div className="right-object">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div id="btm" className="object-cont">
                  <div className="left-icon">
                    <AlternateEmailIcon className="muicon" />
                  </div>
                  <div className="right-object">
                    <input
                      type="text"
                      name="username"
                      required
                      placeholder="Username"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div id="btm" className="object-cont">
                  <div className="left-icon">
                    <LockOpenIcon className="muicon" />
                  </div>
                  <div className="right-object">
                    <input
                      type="password"
                      name="password"
                      required
                      placeholder="Password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div id="btm" className="object-cont">
                  <div className="left-icon">
                    <LockOpenIcon className="muicon" />
                  </div>
                  <div className="right-object">
                    <input
                      type="password"
                      name="confirmPassword"
                      required
                      placeholder="re-type Password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="xtra">
                <div>
                  {" "}
                  <Checkbox />
                  <span>Agree to </span>{" "}
                  <Link to="/policyterms">terms and conditions</Link>
                </div>
              </div>
              <button type="submit" className="form-btn">
                {" "}
                {spinning ? <Spinner /> : " Register"}
              </button>
              <p>Or</p>
            </form>
            <button type="submit" className="form-btn2">
              <Link to="/signin"> Got An Account?</Link>{" "}
            </button>
          </div>
        </div>
        <div className="push"></div>
        <div className="copyrite">
          <Link to="/home">peakTrade Inc. </Link>
          <span>© 2021. All Rights Reserved.</span>
        </div>
      </div>
    </>
  );
}

export default SignUp;
