import Navbartoo from "../Components/Navbartoo";
import Navbar from "../Components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import A1 from "../Images/avi1.png";
import A2 from "../Images/avi2.png";
import A3 from "../Images/avi3.png";
import A4 from "../Images/avi4.png";
import A5 from "../Images/avi5.png";
import A6 from "../Images/avi6.png";

import { Link } from "react-router-dom";

function Testimonials(props) {
  return (
    <>
      <div className="bd monial">
        <Navbar />
        <Navbartoo />
        <h1>Reviews</h1>
      </div>
      <div className="comment-container monial-cont">
        <div className="comment">
          <div className="c-card monialcard">
            <div>
              <img src={A1} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>John Davis</i>
              </h4>
              <p>
                They’re simply the best trading platform out there. Easy to use
                and beginner friendly interface are one of their best
                qualities..
              </p>
            </div>
          </div>
          <div className="c-card monialcard">
            <div>
              <img src={A2} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>Rachel Kings</i>
              </h4>

              <p>
                They’re simply the best trading platform out there. Easy to use
                and beginner friendly interface are one of their best qualities.
              </p>
            </div>
          </div>

          <div className="c-card monialcard">
            <div>
              <img src={A3} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i> Annet Silva</i>
              </h4>

              <p>
                They’re simply the best trading platform out there. Easy to use
                and beginner friendly interface are one of their best qualities.
              </p>
            </div>
          </div>

          <div className="c-card monialcard">
            <div>
              <img src={A4} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>Marybeth Jones</i>
              </h4>

              <p>
                They’re simply the best trading platform out there. Easy to use
                and beginner friendly interface are one of their best qualities.
              </p>
            </div>
          </div>

          <div className="c-card monialcard">
            <div>
              <img src={A5} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>Stanley Joe</i>
              </h4>

              <p>
                They’re simply the best trading platform out there. Easy to use
                and beginner friendly interface are one of their best qualities.
              </p>
            </div>
          </div>

          <div className="c-card monialcard">
            <div>
              <img src={A6} alt="avi" />
            </div>

            <div className="card-txt">
              <h4>
                <i>Grace David</i>
              </h4>

              <p>
                They’re simply the best trading platform out there. Easy to use
                and beginner friendly interface are one of their best qualities.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Testimonials;
