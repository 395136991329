import React from "react";
import OriginalLoader from "react-loader-spinner";

function Spinner(props) {
  return (
    <>
      <OriginalLoader
        className="spinner"
        type="TailSpin"
        color="#fff"
        height={15}
        width={15}
      />
    </>
  );
}

export default Spinner;
