import { BTC_TICKER } from "../Constants";

const initialState = {
  data: {},
};
export default (state = initialState, action) => {
  switch (action?.type) {
    case BTC_TICKER: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default:
      return state;
  }
};
