import Navbartoo from "../../Components/Navbartoo";
import Navbar from "../../Components/Navbar/Navbar";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

function TrustAccount(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Trust Accounts</h1>
      </div>
      <div className="accountall">
        <div>
          <h1>Trust Accounts</h1>
        </div>

        <div className="accountsing">
          <div>
            <p>Trust Accounts</p>
          </div>
        </div>

        <div className="accountsing">
          <div className="accountcon">
            <EmojiPeopleIcon style={{ fontSize: 160 }} />
          </div>
          <div className="accountro">
            <p>
              An account in trust or trust account refers to any type of
              financial account that is opened by an individual and managed by a
              designated trustee for the benefit of a third party per
              agreed-upon terms.
            </p>
          </div>
          <div>
            <li>
              <span>Plan Details</span>– A Plan Suitable For a Single Individual
            </li>
            <p>
              <li>
                <span>Benefits</span> – Afer getting this plan, you gain the
                following benefits:
                <li>You get a Profit of 15% Monthly</li>
                <li>Your Minimum deposit is $1000</li>
                <li>Your Maximum deposit is $10000</li>
              </li>
            </p>

            <p>To get this plan, open an account</p>
            <p>
              <button type="submit" className="myButton">
                <Link to="/signup">Open account</Link>
              </button>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TrustAccount;
