import Navbartoo from "../Components/Navbartoo";
import Reasons from "../Components/Reasons";
import React, { useEffect, useState } from "react";
import Card2 from "../Images/one.jpg";
import P1 from "../Images/bueno.jpg";
import P2 from "../Images/profile1 (2).jpg";
import P3 from "../Images/profile1 (3).jpg";
import P4 from "../Images/profile1 (4).jpg";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";
import SimpleAreaChart from "../Components/Charts/SimpleAreaChart";

function AboutUs(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>About Us</h1>
      </div>
      <div>
        <div className="igbod aubod">
          <h3>What We Do</h3>
          <div className="igcont">
            <div className="autext">
              <p>
                peaktrade is a classic trading platfform that will double your
                cryptocurrency investment, stocks or forex trading… our mission
                is to help you generate more income trading cryptocurrency in a
                unique way…
              </p>
              <p>However,</p>
              <p>
                Bitcoin is a new method to deal with money. One of the most
                important inventions in the entire history of evolution from
                barter trading to the present. Send and receive money from
                anywhere to anyone.
              </p>
              <p>
                The dawn of a new world that is free, better and more
                transparent without restriction!
              </p>
            </div>
            <div className="igimg">
              <img src={Card2}></img>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-container">
        <h4> THE EXPERT TEAM</h4>
        <div className="profile">
          <div className="profile-card">
            <div>
              <img src={P1} alt="" />
            </div>
            <div>
              <h4>John Franklin</h4>
              <p>Chief Strategist in Stock, Binary Options and Forex trading</p>
            </div>
            <br />
            <br />
          </div>
          <div className="profile-card">
            <div>
              <img src={P2} alt="" />
            </div>
            <div>
              <h4>Hans Chris</h4>
              <p>
                Professional trader in Stock, binary options and Forex trading
              </p>
            </div>
            <br />
            <br />
          </div>
          <div className="profile-card">
            <div>
              <img src={P3} alt="" />
            </div>
            <div>
              <h4>Samuel Moore</h4>
              <p>
                SEO expert and top broker in Stock, binary and Forex trading
              </p>
            </div>
            <br />
            <br />
          </div>
          <div className="profile-card">
            <div>
              <img src={P4} alt="" />
            </div>
            <div>
              <h4>Charles Tatum</h4>
              <p>Signal Analyst in Stock, binary options and Forex trading</p>
            </div>
            <br />
            <br />
          </div>
        </div>
        <div className="btm">
          <h1>Explore How we can help you</h1>
          <p>
            The ultimate trading solution <br /> for busy personales who want to
            reach their trading goals
          </p>
        </div>
      </div>
      <Reasons />
      <div className="ipcontact">
        <div className="ipcontacttext">
          <h1>DO YOU HAVE ANY QUESTION OR WANT TO KNOW MORE ABOUT US…</h1>
          {/* <p>CONTACT US NOW</p> */}
        </div>
        <div>
          <button type="submit" className="myButton">
            Contact Us
          </button>
        </div>
      </div>
      <div className="push1"></div>
      <Footer />
    </>
  );
}

export default AboutUs;
