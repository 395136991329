import Navbartoo from "../../Components/Navbartoo";
import Navbar from "../../Components/Navbar/Navbar";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

function RetirementAccount(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Retirement Accounts</h1>
      </div>
      <div className="accountall">
        <div>
          <h1>Retirement Accounts</h1>
        </div>

        <div className="accountsing">
          <div className="accountcon">
            <GroupsTwoToneIcon style={{ fontSize: 160, color: "gray" }} />
          </div>
          <div className="accountro">
            <p>
              An individual retirement account is a savings account with tax
              advantages that individuals can save and invest long-term.
            </p>
          </div>
          <div>
            <li>
              <span>Plan Details</span>– A Plan Suitable For a Single Individual
            </li>
            <p>
              <li>
                <span>Benefits</span> – Afer getting this plan, you gain the
                following benefits:
                <li>You get a Profit of 25% Monthly</li>
                <li>Your Minimum deposit is $2500</li>
                <li>Your Maximum deposit is Unlimited</li>
              </li>
            </p>

            <p>To get this plan, open an account</p>
            <p>
              <button type="submit" className="myButton">
                <Link to="/signup">Open account</Link>
              </button>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default RetirementAccount;
