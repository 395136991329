import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Logo from "../Images/fidelogo.png";

export default function ConfirmEmail() {
  const history = useHistory();
  return (
    <>
      <div className="signin" id="confirm">
        <div id="active">
          {" "}
          <Link to="/">
            {" "}
            <img src={Logo} />
          </Link>
        </div>
        <div className="push1"></div>
        <div className="push1"></div>
        <div className="push1"></div>

        <div className="auth-container" id="conf">
          <h1>Welcome!</h1>
          <p>Email Confirmed Successfully</p>
          <div className="c-form"></div>
          <button
            type="submit"
            className="form-btn"
            onClick={() => history.push("/signin")}
          >
            {" "}
            Login
          </button>
        </div>
        <div className="push"></div>
        <div className="push-md"></div>

        <div className="copyrite">
          <Link to="/">PeakTrade Inc. </Link>
          <span>© 2021. All Rights Reserved.</span>
        </div>
      </div>
    </>
  );
}
