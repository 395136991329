import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../Images/cover.png";

export default function Navbartoo() {
  const [open, setOpen] = useState(false);
  console.log(open);
  const handleMenu = () => {
    setOpen(!open);
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="navbar" id="too">
        <div className={open ? "menu closee " : "none-lg"} onClick={handleMenu}>
          <CloseIcon />
        </div>
        <div className={!open ? "menu open" : "none-lg"} onClick={handleMenu}>
          <MenuIcon />
        </div>
        <div className="navlist">
          {" "}
          <Link to="/">
            {" "}
            <img src={Logo} />
          </Link>
        </div>

        {/* <div className={open ? "navlist" : "none"}>
          <Link to="/signin">SignIn</Link>
        </div> */}
        <div className={open ? "navlist" : "none"}>
          <Link to="/about">About</Link>
        </div>
        <div className={open ? "navlist" : "none"}>
          <Link to="/stocktrading">{t("stock")}</Link>
        </div>
        <div className={open ? "navlist" : "none"}>
          <Link to="/cryptocurrency">CryptoCurrencies</Link>
        </div>
        <div className={open ? "navlist" : "none"}>
          <Link to="/forex">ForexTrading</Link>
        </div>
        <div className={open ? "navlist" : "none"}>
          <Link to="/testimonials">Testimonials</Link>
        </div>
        <div className={open ? "navlist" : "none"}>
          <Link to="/signup">SignUp</Link>
        </div>
      </div>
    </>
  );
}
