import Navbartoo from "../Components/Navbartoo";
import Navbar from "../Components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import Card2 from "../Images/forexone.jpeg";
import Card3 from "../Images/forextwo.jpeg";
import A4 from "../Images/avi1.png";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function ForexTrading(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Forex</h1>
      </div>
      <div>
        <div className="igbod">
          <h1>Essentials of forex trading with peakTrade</h1>
          <div className="igcont">
            <div className="igtext">
              With over 200 forex pairs in our market coverage, you can trade
              your favorite pairs, with our top notch technology, our state of
              the art tools and advice from our team of Pro traders who will
              share ideas with you from their wealth of experience.
              <p>
                <button type="submit" className="myButton">
                  <Link to="/signup">Open account</Link>
                </button>
              </p>
            </div>
            <div className="igimg">
              <img src={Card2}></img>
            </div>
          </div>
        </div>
        <div className="igbod">
          <h1>Benefits of trading forex with peakTrade</h1>
          <div className="igcont">
            <div className="igimg">
              <img src={Card3}></img>
            </div>
            <div className="igtext">
              <li>
                <span>No extra charges</span>– At peaktrades we do not charge
                any fees for trading on our platform, except access fees,
                subscription fees and transaction fees.
              </li>
              <li>
                <span>Mobile trading</span> – Our mobile app enable you to lock
                in trades and monitor the market with your mobile devices on the
                go.
              </li>
              <li>
                <span>Insurance of capital</span> – One of the most innovative
                features of peaktrades is that we offer investors the
                opportunity to insure their capital. To ensure that they don’t
                lose all of their investments even if trades go bad.
              </li>
              {/* <button type="submit" className="myButton">
                Learn More
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default ForexTrading;
