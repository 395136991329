import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export default function Counter({ className, ...rest }) {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  // remove this comment
  return (
    <div>
      <CountUp
        {...rest}
        start={viewPortEntered ? null : 0}
        delay={0}
        duration={3}
      >
        {({ countUpRef }) => {
          return (
            <VisibilitySensor
              active={!viewPortEntered}
              onChange={(isVisible) => {
                if (isVisible) {
                  setViewPortEntered(true);
                }
              }}
              delayedCall
            >
              <h4 className={className} ref={countUpRef} />
            </VisibilitySensor>
          );
        }}
      </CountUp>
    </div>
  );
}
