import logo from "./logo.svg";

import "./App.css";
import { Provider, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Home from "./Routes/Home";
import SignIn from "./Auth/SignIn";
import InvestmentProducts from "./Routes/InvestmentProducts";
import InvestmentGuidance from "./Routes/InvestmentGuidance";
import SignUp from "./Auth/SignUp";
import AboutUs from "./Routes/AboutUs";
import Testimonials from "./Routes/Testimonials";
import ForgotPassword from "./Auth/ForgotPassword";
import ResetPassword from "./Auth/ResetPassword";
import Cryptocurrency from "./Routes/Crytocurrency";
import StockTrading from "./Routes/StockTrading";
import store from "../src/Redux/Store";
import FamilyAccount from "./Routes/Accounts/FamilyAccounts";
import IndividualAccount from "./Routes/Accounts/IndividualAccounts";
import InstitutionalAccount from "./Routes/Accounts/InstitutionalAccounts";
import JointAccount from "./Routes/Accounts/JointAccounts";
import RetirementAccount from "./Routes/Accounts/RetirementAccounts";
import TrustAccount from "./Routes/Accounts/TrustAccounts";
import ForexTrading from "./Routes/ForexTrading";
import { lazy, Suspense } from "react";
import ConfirmEmail from "./Auth/ConfirmEmail";
import { ProtectedRoutes } from "./ProtectedRoutes";
import ErrorScreen from "./Routes/404";
import SuspensePage from "./Routes/SuspensePage";
import Referral from "./Auth/Referral";

const Overview = lazy(() => import("./Routes/Dashboard/Overview"));
const Referrals = lazy(() => import("./Routes/Dashboard/Referrals"));

const Disputes = lazy(() => import("./Routes/Dashboard/Disputes"));

const Plans = lazy(() => import("./Routes/Dashboard/Plans"));
const FundAccounts = lazy(() => import("./Routes/Dashboard/FundAccounts"));
const Payouts = lazy(() => import("./Routes/Dashboard/Payouts"));
const Profile = lazy(() => import("./Routes/Dashboard/Profile"));
const ChangePassword = lazy(() => import("./Routes/Dashboard/ChangePassword"));

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />

          <Route exact path="/signin" component={SignIn} />
          <Route path="/signup/:uin" component={SignUp} />
          <Route path="/signup" component={SignUp} />

          <Route exact path="/referral/:uin/:user" component={Referral} />
          {/* <Route exact path="/confirmemail" component={ConfirmEmail} /> */}
          <Route exact path="/errorscreen" component={ErrorScreen} />

          <Route exact path="/resetpassword/:token" component={ResetPassword} />
          <Route exact path="/verifyemail/:token" component={ConfirmEmail} />

          <Route
            exact
            path="/investment-products"
            component={InvestmentProducts}
          />
          <Route
            exact
            path="/investment-guidance"
            component={InvestmentGuidance}
          />
          <Route exact path="/about" component={AboutUs} />
          <Route exact path="/testimonials" component={Testimonials} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/cryptocurrency" component={Cryptocurrency} />
          <Route exact path="/forex" component={ForexTrading} />
          <Route exact path="/stocktrading" component={StockTrading} />
          <Route exact path="/family" component={FamilyAccount} />
          <Route exact path="/individual" component={IndividualAccount} />
          <Route exact path="/institutional" component={InstitutionalAccount} />
          <Route exact path="/jointaccount" component={JointAccount} />
          <Route exact path="/retirement" component={RetirementAccount} />
          <Route exact path="/trust" component={TrustAccount} />

          <Suspense fallback={<SuspensePage />}>
            <ProtectedRoutes
              exact
              path="/dashboard/overview"
              component={Overview}
            />
            <ProtectedRoutes
              exact
              path="/dashboard/referrals"
              component={Referrals}
            />
            <ProtectedRoutes
              exact
              path="/dashboard/disputes"
              component={Disputes}
            />
            <ProtectedRoutes exact path="/dashboard/plans" component={Plans} />
            <ProtectedRoutes
              exact
              path="/dashboard/payouts"
              component={Payouts}
            />
            <ProtectedRoutes
              exact
              path="/dashboard/profile"
              component={Profile}
            />
            <ProtectedRoutes
              exact
              path="/dashboard/changepassword"
              component={ChangePassword}
            />
            <ProtectedRoutes
              exact
              path="/dashboard/fundaccounts"
              component={FundAccounts}
            />
          </Suspense>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
