import Navbartoo from "../../Components/Navbartoo";
import Navbar from "../../Components/Navbar/Navbar";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

function IndividualAccount(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Individual Account</h1>
      </div>
      <div className="accountall">
        <div>
          <h1>Individual Accounts</h1>
        </div>

        <div className="accountsing">
          <div className="accountcon">
            <PersonOutlineIcon style={{ color: "ab8353", fontSize: 160 }} />
          </div>
          <div className="accountro">
            <p>
              An account at a brokerage, bank, company or anywhere that a single
              person is responsible for debits and credits thereto. An
              individual account contrasts with a joint account and a
              partnership account, both of which may have more than one account
              holder.
            </p>
          </div>
          <div>
            <li>
              <span>Plan Details</span>– A Plan Suitable For a Single Individual
            </li>
            <p>
              <li>
                <span>Benefits</span> – Afer getting this plan, you gain the
                following benefits:
                <li>You get a Profit of 15% daily</li>
                <li>Your Minimum deposit is $100</li>
                <li>Your Maximum deposit is $1000</li>
              </li>
            </p>

            <p>To get this plan, open an account</p>
            <p>
              <button type="submit" className="myButton">
                <Link to="/signup">Open account</Link>
              </button>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default IndividualAccount;
