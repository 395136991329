import Navbartoo from "../Components/Navbartoo";
import Navbar from "../Components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import Card2 from "../Images/cryptoone.jpeg";
import Card3 from "../Images/cryptotwo.jpeg";
import A4 from "../Images/avi1.png";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function Cryptocurrency(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Cryptocurrency</h1>
      </div>
      <div>
        <div className="igbod">
          <h1>Trading Cryptocurrencies With peakTrade</h1>
          <div className="igcont">
            <div className="igtext">
              Investments in Cryptocurrencies has grown exponentially over the
              past few years, making Cryptocurrency trading and investment one
              of the fastest growing online businesses in the past decade. We
              support a variety of over 120 Cryptocurrencies to trade and invest
              in. Such as Bitcoin, Ethereum, Litecoin, Ripple and many more.
              <p>
                <button type="submit" className="myButton">
                  <Link to="/signup">Open account</Link>
                </button>
              </p>
            </div>
            <div className="igimg">
              <img src={Card2}></img>
            </div>
          </div>
        </div>
        <div className="igbod">
          <h1>Benefits of trading Cryptocurrencies with peakTrade</h1>
          <div className="igcont">
            <div className="igimg">
              <img src={Card3}></img>
            </div>
            <div className="igtext">
              <li>
                <span>No extra charges</span>– Get a managed portfolio
                recommendation to fit your goals and ongoing support from
                peaktrades Investment Management, LLC
              </li>
              <li>
                <span>Mobile trading</span> – For more complex advisory needs,
                get an introduction to an independent Registered Investment
                Advisor through our advisor referral program
              </li>
              <li>
                <span>Insurance of capital</span> – For more complex advisory
                needs, get an introduction to an independent Registered
                Investment Advisor through our advisor referral program
              </li>
              {/* <button type="submit" className="myButton">
                Learn More
              </button> */}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Cryptocurrency;
