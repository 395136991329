import React from "react";
import OriginalLoader from "react-loader-spinner";

function Loader(props) {
  return (
    <>
      <OriginalLoader
        className="loader"
        type="TailSpin"
        color="#00204a"
        height={40}
        width={40}
      />
    </>
  );
}

export default Loader;
