import Navbartoo from "../../Components/Navbartoo";
import Navbar from "../../Components/Navbar/Navbar";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

function FamilyAccount(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Family Account</h1>
      </div>
      <div className="accountall">
        <div>
          <h1>Family Accounts</h1>
        </div>

        <div className="accountsing">
          <div className="accountcon">
            <GroupsIcon style={{ color: "ab8353", fontSize: 160 }} />
          </div>
          <div className="accountro">
            <p>
              Family Account means any brokerage or other account containing
              securities (including but not limited to covered securities) in
              which an immediate family member of the Access Person not sharing
              the same household has Beneficial Ownership and over which the
              Access Person exercises direct or indirect, sole or shared,
              investment control.
            </p>
          </div>
          <div>
            <li>
              <span>Plan Details</span>– A Plan Suitable For Accounts Made for
              Friends and Family, Available after Registration
            </li>
            <p>
              <li>
                <span>Benefits</span> – Afer getting this plan, you gain the
                following benefits:
                <li>You get a Profit of 15% daily</li>
                <li>Your Minimum deposit is $5000</li>
                <li>Your Maximum deposit is $20000</li>
              </li>
            </p>

            <p>To get this plan, open an account</p>
            <p>
              <button type="submit" className="myButton">
                <Link to="/signup">Open account</Link>
              </button>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FamilyAccount;
