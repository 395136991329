import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_ZH } from "./Chinese_zh";
import { TRANSLATIONS_EN } from "./English_en";
import { TRANSLATIONS_DE } from "./German_De";
import { TRANSLATIONS_ES } from "./Spanish_es";
import { TRANSLATIONS_RU } from "./Russian_ru";
const fallbackLng = ["ar"];
const availableLanguages = ["en", "ar", "fr"];

i18n
  .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      zh: {
        translation: TRANSLATIONS_ZH,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      ru: {
        translation: TRANSLATIONS_RU,
      },
    },
  });
i18n.changeLanguage("en");

export default i18n;
