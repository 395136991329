import Navbartoo from "../../Components/Navbartoo";
import Navbar from "../../Components/Navbar/Navbar";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

function InstitutionalAccount(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Institutional Accounts</h1>
      </div>
      <div className="accountall">
        <div>
          <h1>Institutional Accounts</h1>
        </div>

        <div className="accountsing">
          <div className="accountcon">
            <EmojiTransportationIcon style={{ fontSize: 160 }} />
          </div>
          <div className="accountro">
            <p>
              An account that's opened by or for an institution (like an
              insurance company or brokerage) for the benefit of banks, mutual
              funds, or others. These accounts usually have lower costs and
              commissions and let money flow between institutions more easily.
              There's lots of cash involved, but there's actually no minimum to
              open one.
            </p>
          </div>
          <div>
            <li>
              <span>Plan Details</span>– A Plan Suitable For an Institutional
              Body
            </li>
            <p>
              <li>
                <span>Benefits</span> – Afer getting this plan, you gain the
                following benefits:
                <li>You get a Profit of 45% Monthly</li>
                <li>Your Minimum deposit is $10000</li>
                <li>Your Maximum deposit is Unlimited</li>
              </li>
            </p>

            <p>To get this plan, open an account</p>
            <p>
              <button type="submit" className="myButton">
                <Link to="/signup">Open account</Link>
              </button>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default InstitutionalAccount;
