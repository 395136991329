import Loader from "../Utilities/Loader";
export default function () {
  return (
    <>
      <div className="suspense">
        <div className="sus">
          <Loader />
        </div>
      </div>
    </>
  );
}
