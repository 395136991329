import Navbartoo from "../Components/Navbartoo";
import Navbar from "../Components/Navbar/Navbar";
import React, { useEffect, useState } from "react";
import Card2 from "../Images/one.jpg";
import Card3 from "../Images/four.jpg";
import A4 from "../Images/avi1.png";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";

function InvestmentGuidance(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Investment Guidance</h1>
      </div>
      <div>
        <div className="igbod">
          <h1>Investment Guidance that Starts with you</h1>
          <div className="igcont">
            <div className="igtext">
              Your goals are unique, so your investment guidance should be too.
              We take the time to listen and understand what matters most to you
              before helping you develop and manage your investment strategy.
              Contact us today at support@peaktrades.com to get started.
            </div>
            <div className="igimg">
              <img src={Card2}></img>
            </div>
          </div>
        </div>
        <div className="igbod">
          <h1>
            A Range of Investment Management Solutions for Today's Investor
          </h1>
          <div className="igcont">
            <div className="igimg">
              <img src={Card3}></img>
            </div>
            <div className="igtext">
              <li>
                <span>Build it with us</span>– Get a managed portfolio
                recommendation to fit your goals and ongoing support from
                peaktrades Investment Management, LLC
              </li>
              <li>
                <span>Build it with an advisor</span> – For more complex
                advisory needs, get an introduction to an independent Registered
                Investment Advisor through our advisor referral program
              </li>
            </div>
          </div>
        </div>
        <div className="igbod">
          <h1>Whatever your goals, we’ll help you craft a plan</h1>
          <div className="igcont">
            <div className="igtext">
              We offer a broad range of products and services to help you pursue
              your financial goals, and we can help you choose the right
              approach.
            </div>
            <div className="igimg">
              <img src={A4}></img>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default InvestmentGuidance;
