import Navbartoo from "../../Components/Navbartoo";
import Navbar from "../../Components/Navbar/Navbar";
import React from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import GroupsIcon from "@mui/icons-material/Groups";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import Footer from "../../Components/Footer";
import { Link } from "react-router-dom";

function JointAccount(props) {
  return (
    <>
      <div className="bd">
        <Navbar />
        <Navbartoo />
        <h1>Joint Accounts</h1>
      </div>
      <div className="accountall">
        <div>
          <h1>Joint Accounts</h1>
        </div>

        <div className="accountsing">
          <div className="accountcon">
            <PeopleOutlineIcon style={{ fontSize: 160 }} />
          </div>
          <div className="accountro">
            <p>
              A joint account is a bank or brokerage account shared between two
              or more individuals. Joint accounts are most likely to be used by
              relatives, couples, or business partners who have a level of
              familiarity and trust with each other. It typically allows anyone
              named on the account to access funds within it. There are multiple
              ways accounts can be established, each with its own implications
              for how money or assets can be accessed within the account or how
              the contents of the account are handled after one of the joint
              holders passes away.
            </p>
          </div>
          <div>
            <li>
              <span>Plan Details</span>– A Plan Suitable For Joint Bodies or
              Individuals
            </li>
            <p>
              <li>
                <span>Benefits</span> – Afer getting this plan, you gain the
                following benefits:
                <li>You get a Profit of 25% in 3days</li>
                <li>Your Minimum deposit is $500</li>
                <li>Your Maximum deposit is $5000</li>
              </li>
            </p>

            <p>To get this plan, open an account</p>
            <p>
              <button type="submit" className="myButton">
                <Link to="/signup">Open account</Link>
              </button>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default JointAccount;
